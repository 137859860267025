import React, { createContext, useState, useEffect } from 'react'
import firebase from 'firebase/compat/app'
import { navigate } from 'gatsby'
import { CustomAlert } from '../components/Alert'
import useSessionCheck from '../hooks/useSessionCheck'

export const AuthContext = createContext({
  user: undefined,
  setUser: (user: any) => console.warn('no user provided'),
})

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<firebase.User>()
  const { alertMessage, alertType, alertOpen, setAlertOpen, checkSession } = useSessionCheck();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser)
        await checkSession(currentUser);
      }
    })

    return () => unsubscribe()
  }, [navigate])

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
      <CustomAlert
        alertMessage={alertMessage}
        alertType={alertType}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      />
    </AuthContext.Provider>
  )
}

export default AuthProvider
