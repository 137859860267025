import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { navigate } from 'gatsby';

const useSessionCheck = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const checkSession = async (currentUser: firebase.User) => {
    const userSessionRef = firebase.firestore().collection('sessions').doc(currentUser.uid);
    const sessionDoc = await userSessionRef.get();

    if (sessionDoc.exists) {
      const sessionData = sessionDoc.data();
      console.log('Session ID:', sessionData?.sessionId);

      // Check if session ID matches
      if (sessionData?.sessionId !== localStorage.getItem('sessionId')) {
        // If session is different, sign out and navigate to sign-in page
        await firebase.auth().signOut().then(() => {
          // Alert user and handle session expiration
          setAlertMessage('You are logged in on another device.');
          setAlertType('failed');
          setAlertOpen(true);

          // Clean up local storage and session
          setTimeout(() => {
            localStorage.removeItem('user');
            localStorage.removeItem('sessionId');
            sessionStorage.clear();
            navigate('/signin', { state: { path: window.location } });
          }, 1000);
        });
      }
    } else {
      console.log('No session found for this user.');
    }
  };

  return {
    alertMessage,
    alertType,
    alertOpen,
    setAlertOpen,
    checkSession,
  };
};

export default useSessionCheck;
