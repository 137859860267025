import React, { useEffect, useState } from 'react';
import addNotification from '@purnamasari/react-push-notification';
import { Alert, Button, Typography } from "@material-tailwind/react";
import { colors } from '@material-tailwind/react/types/generic';

export default function successNoti(subtitle: string, msg: string) {
  addNotification({
    title: 'Success',
    subtitle: subtitle,
    message: msg,
    duration: 10000,
    theme: 'darkblue',
    closeButton: "X",
    backgroundTop: "green",
    backgroundBottom: "darkgreen"
  });
}

export function errorNoti(subtitle: string, msg: string) {
  addNotification({
    title: 'Error',
    subtitle: subtitle,
    message: msg,
    duration: 5000,
    theme: 'darkblue',
    closeButton: "X",
    backgroundTop: "red",
    backgroundBottom: "yelloworange"
  });
}

export function infoNoti(subtitle: string, msg: string) {
  addNotification({
    title: 'Hey',
    subtitle: subtitle,
    message: msg,
    duration: 5000,
    theme: 'darkblue',
    closeButton: "X",
    backgroundTop: "green",
    backgroundBottom: "yellowblue",
  });
}

export function CustomAlert({ alertType, alertMessage, open, onClose }: { alertType: string, alertMessage: string, open: boolean, onClose: () => void }) {
  const [isOpen, setOpen] = useState(open);
  
  useEffect(() => {
    setOpen(open);
  }, [open]);
  
  let alertTitle = '';
  let alertColor = 'green';

  switch (alertType) {
    case 'success':
      alertTitle = 'Success';
      alertColor = 'green';
      break;
    case 'failed':
      alertTitle = 'Failed';
      alertColor = 'red';
      break;
    case 'info':
      alertTitle = 'Info';
      alertColor = 'blue';
      break;
    default:
      alertTitle = 'Success';
      break;
  }

  return (
    <Alert
      open={isOpen}
      className="max-w-screen-sm absolute z-20 
      sm:top-0 sm:left-0 lg:top-auto lg:left-auto lg:bottom-0 lg:right-0 lg:mx-0 lg:m-5 sm:mx-auto"
      color={alertColor as colors | undefined}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <Typography variant="h5" color="white">
        {alertTitle}
      </Typography>
      <Typography color="white" className="mt-2 font-normal">
        {alertMessage}
      </Typography>
    </Alert>
  );
}